(function( $ ){
  $.fn.eqsSuggest = function(options) {
  
    var settings = {
	  'inputID'         : 'search_input',
	  'sizeID'          : 'search_input',
	  'resize'          : true,
      'maxKeywords'     : 10,
      'searchFormName'  : 'searchForm',
	  'hitLabel'        : 'Treffer',
	  'hitBefore'       : '(',
	  'hitAfter'        : ')',
	  'defaultKeys'     : [],
	  'padding'         : 10,
	  'outputNodeClass'    : undefined
    };
	
	var methods = {
	  showOutput : function (output) {
		var pos = $('#'+settings.sizeID).position();
		var default_width = ($('#'+settings.sizeID).outerWidth() - (settings.padding*2));
		
		if (output.html()=='') {
			$('.searchOutput').slideUp();
			return;
		}
		
		$('.searchOutput').css('width', default_width + "px").children().remove();
		$('.searchOutput').append(output);
		$('.searchOutput').slideDown();
		
		var current_width = $('.searchOutput table').outerWidth();
		var css = {
				  "left" : pos.left + "px",
				  "top"  : pos.top + $('#'+settings.sizeID).height() + "px",
				  "width": current_width > default_width ? (current_width + (settings.padding*2)) + "px" : default_width
		};
		$('.searchOutput').css(css);
	  },
	  setKeyword : function (key) {
		$('#' + settings.inputID).attr('value',key);
		$('.searchOutput').html = "";
		$('.searchOutput').css('display', "none");
		document.forms[settings.searchFormName].submit();
	  }
	}
	
	return this.each(function() {        
      if ( options ) { 
        $.extend( settings, options );
      }

	  settings.inputID = $(this).attr('id');
	  
	  var div = $('<div class="searchOutput"></div>');
	  div.css({
		'display':'none',
		'position':'absolute',
		'z-index':'999'
	  });
	  if (settings.outputNodeClass) {
		div.appendTo($('#'+settings.outputNodeClass));
	  } else {
		div.appendTo(document.body);
	  }
	  
	  $(window).resize(function() {
		  var pos = $('#'+settings.sizeID).position();
		  var css = {
					  "left" : pos.left + "px",
					  "top"  : pos.top + ($(settings.sizeID).outerHeight()) + "px"
		  };
		  $('.searchOutput').css(css);
	  });
	    
	  $(this).focus(function() {
		$(this).val("");
		var output = "";
		var table  = $('<table />');
		if (settings.defaultKeys.length > 0) {
		  for(var i = 0; i < settings.defaultKeys.length; i++){
			  var tr = $('<tr></tr>');
			  var td = $('<td class="keywordCol"></td>');
			  td.text(settings.defaultKeys[i]);
			  td.click(function() {
				methods.setKeyword($(this).text());
			  })
			  td.appendTo(tr);
			  tr.appendTo(table);
		  }
		  methods.showOutput(table);
		}
	  });


	  $(this).keyup(function() {
		var keyword = $(this).val();
		var table  = $('<table />');
		var count = 0;
		for(var i = 0; i < window.myKeys.length; i++){
		  var _regex = new RegExp( "^" + keyword + "\w*", "i");
		  if(window.myKeys[i].match(_regex)){
			var tr = $('<tr></tr>');
			var td = $('<td class="keywordCol"><a href="javascript: void(0);">'+window.myKeys[i]+'</a></td>');
			td.click(function() {
			  methods.setKeyword($(this).text());
			})
			td.appendTo(tr);
			var td = $('<td class="numberCol"></td>')
			td.text(settings.hitBefore + window.myWeight[window.myKeys[i]] + settings.hitAfter);
			td.appendTo(tr);
			tr.appendTo(table);
			if (count == settings.maxKeywords - 1) {
			  break;
			}
			count++;
		  }
		}
		methods.showOutput(table)
	  });
	  
	  $(this).blur(function() {
		setTimeout(function(){$('.searchOutput').slideUp();}, 500);
	  });
    });
  };
})( jQuery );
